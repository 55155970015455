import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ENV } from '../../../../conf/ENV';
import { ResponseInformationObjectInterface } from '../../../wrappers/response-information-object-interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuFootProviderService {

  public environment: any;
  public configUrl: any;

  constructor( private http: HttpClient, private env: ENV) {
    this.environment = env.getEnv();
    this.configUrl = this.environment.API_URL + "/menu/";
  }

  /**
   * Envia la solicitud a la Api para la consulta de los elementos del menu foot
   *
   * @returns respuesta del servidor o mensaje de error
   */
   consultaMenuFoot(  ){
     let accion = 'consultaMenuFoot/';
     let headers = new HttpHeaders({
       'content-type': 'application/json',
     });
     var options =  {
         headers: headers
     };
     return this.http.get<ResponseInformationObjectInterface>( this.configUrl+accion, options);

   }
}
