import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
//providers
import { SedeProviderService } from '../../providers/sedes/sede-provider.service';

@Component({
  selector: 'app-sede',
  templateUrl: './sede.component.html',
  styleUrls: ['./sede.component.css']
})
export class SedeComponent implements OnInit {

  public urlSede: any = "";
  public ciudades: any = [];
  public datosSede : any = {};
  lat: number = 4.664993;
  lng: number = -74.060731;
  constructor( private sedeProvider: SedeProviderService, private router: Router, private activateRoute: ActivatedRoute, private sanitizer: DomSanitizer, private meta: Meta, private title: Title, @Inject(DOCUMENT) private document: any ){

  }

  ngOnInit() {
    this.activateRoute.paramMap.subscribe(
      ( map ) => {
        let that = this;
        that.urlSede = map.get('url');
        that.cargarDatos( that.urlSede );
        that.cargarMenuSedes();
      }
    );
  }

  /**
   * Obtiene la informacion de la pagina
   *
   * @param String   la url de la pagina 'web/Urlpagina'
   */
   cargarDatos( url: any ){
     let urlcompleta = "/"+url;
     let datos = {
      url: urlcompleta,
      }
     this.sedeProvider.consultarSeoSede( datos ).subscribe(
       ( data ) => {
         let that = this;
         if(data.datos.seo == null ){
           data.datos.seo = {};
         }
         that.cargarSeo( data.datos.seo );
         that.cargarInformacion( data.datos );
       },
       ( error ) => {
         if( error.status == 500 ){
           console.log( "error en el servidor");
         }
       }
     );
   }

  /**
   * Carga el contenido del seo de la pagina
   *
   * @param Object   datos del seo
   */
   cargarSeo( seo ){
     this.title.setTitle( this.decode_utf8( seo.titulo ) );

     let link: HTMLLinkElement = this.document.createElement('link');
     link.setAttribute('rel', 'canonical');
     this.document.head.appendChild(link);
     link.setAttribute('href', seo.UrlPortal+'/inicio');

    let link3: HTMLLinkElement = this.document.createElement('link');
    link3.setAttribute('rel', 'shortcut icon');
    this.document.head.appendChild(link3);
    link3.setAttribute('href', seo.imagen);
     this.meta.addTag({ name: 'robots', content: 'all' });
     this.meta.addTag({ name: 'description', content: this.decode_utf8( seo.descripcion ) });
     this.meta.addTag({ name: 'keywords', content: this.decode_utf8( seo.palabrasClaves) });
     this.meta.addTag({ name: 'author', content: seo.autor });

     this.meta.addTag({ itemprop: 'name', content: seo.tituloPortal});
     this.meta.addTag({ itemprop: 'url', content: seo.UrlPortal });
     this.meta.addTag({ itemprop: 'thumbnailUrl', content: seo.imagen });
     let link2: HTMLLinkElement = this.document.createElement('link');
     link2.setAttribute('rel', 'image_src');
     this.document.head.appendChild(link2);
     link2.setAttribute('href', seo.imagen);
     this.meta.addTag({ itemprop: 'image', content: seo.imagen });

     this.meta.addTag({ name: 'evisit-after', content: '1 days' });
     this.meta.addTag({ name: 'distribution', content: 'global' });
     this.meta.addTag({ name: 'format-detection', content: 'telephone=no' });

     //facebook
     this.meta.addTag({ property: 'og:locale', content: 'es_LA' });
     this.meta.addTag({ property: 'og:type', content: 'Organization' });
     this.meta.addTag({ property: 'og:title', content: seo.titulo });
     this.meta.addTag({ property: 'og:description', content: seo.descripcion });
     this.meta.addTag({ property: 'og:url', content: seo.UrlPortal+'/inicio' });
     this.meta.addTag({ property: 'og:site_name', content: seo.tituloPortal });
     this.meta.addTag({ property: 'og:image', content: seo.imagen });

     this.meta.addTag({ property: 'fb:app_id', content: seo.fbAppid });

     //twitter
     this.meta.addTag({ name: 'twitter:card', content: 'summary' });
     this.meta.addTag({ name: 'twitter:site', content: seo.UrlPortal });
     this.meta.addTag({ name: 'twitter:title', content: seo.titulo });
     this.meta.addTag({ name: 'twitter:description', content: seo.descripcion });
     this.meta.addTag({ name: 'twitter:creator', content: seo.autor });


   }

   /**
    * Carga el los datos principales de la sede
    *
    * @param Object   informacion de la sede
    */
   cargarInformacion ( datos ){
     let coordenadas = datos.coordenadas;
     this.lat = coordenadas[0].lat;
     this.lng = coordenadas[0].lng;
     this.datosSede = datos;
   }

   cargarMenuSedes(){
     this.sedeProvider.consultaCiudadesSedes(  ).subscribe(
       ( data ) => {
         let that = this;
         that.ciudades = data.datos;
       },
       ( error ) => {
         if( error.status == 500 ){
           console.log( "error en el servidor");
         }
       }
     );
   }

   /**
    * Decodifica el utf8 recibido
    *
    * @param String    texto para decodificar
    */
   decode_utf8(s) {
     try{
         return decodeURIComponent(escape(s));
     }catch(err){
       return s.replace("Ã‰", "É");
     }
   }
}
