import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer, Meta, Title } from '@angular/platform-browser';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {map} from 'rxjs/operators';
import {OwlCarousel} from 'ngx-owl-carousel';
//providers
import { PaginaProviderService } from '../../providers/paginas/paginas/pagina-provider.service';

@Component({
  selector: 'app-convenio',
  templateUrl: './convenio.component.html',
  styleUrls: ['./convenio.component.css']
})
export class ConvenioComponent implements OnInit {

  @ViewChild('owlElement') owlElement: OwlCarousel;

  public contenido: any;
  public convenios: any = [];
  public vistaConvenio: any = {};


  constructor( private PaginaService: PaginaProviderService, private router: Router, private activateRoute: ActivatedRoute, private sanitizer: DomSanitizer, private meta: Meta, private title: Title, @Inject(DOCUMENT) private document: any, private location: Location ){
  }

  ngOnInit() {
    this.cargarDatos( );
  }

  /**
   * Obtiene la informacion de la pagina
   *
   * @param String   la url de la pagina 'web/Urlpagina'
   */
   cargarDatos(  ){
     let datos = {};
     this.PaginaService.consultarConvenios( datos ).subscribe(
       ( data ) => {
         let that = this;
         let datos = data.datos;
         that.convenios = datos.convenios;
         that.cargarSeo( datos.seo );
       },
       ( error ) => {
         console.log( error );
       }
     );

   }

   /**
    * Carga el contenido de la pagina que retorna el servidor
    *
    * @param String   codigo html almacenado en la base de datos
    */
   verConvenio( convenio ){
     this.vistaConvenio = convenio;
     this.contenido = this.sanitizer.bypassSecurityTrustHtml( convenio.descripcion );
   }

   /**
    * Carga el contenido del seo de la pagina
    *
    * @param Object   datos del seo
    */
   cargarSeo( seo ){
     this.title.setTitle( this.decode_utf8( seo.titulo ) );

     let link: HTMLLinkElement = this.document.createElement('link');
     link.setAttribute('rel', 'canonical');
     this.document.head.appendChild(link);
     link.setAttribute('href', window.location.href);

     this.meta.addTag({ name: 'robots', content: 'all' });
     this.meta.addTag({ name: 'description', content: this.decode_utf8( seo.descripcion ) });
     this.meta.addTag({ name: 'keywords', content: this.decode_utf8( seo.palabrasClaves ) });
     this.meta.addTag({ name: 'author', content: seo.autor });

     this.meta.addTag({ itemprop: 'name', content: seo.tituloPortal});
     this.meta.addTag({ itemprop: 'url', content: seo.UrlPortal });
     this.meta.addTag({ itemprop: 'thumbnailUrl', content: seo.imagen });
     let link2: HTMLLinkElement = this.document.createElement('link');
     link2.setAttribute('rel', 'image_src');
     this.document.head.appendChild(link2);
     link2.setAttribute('href', seo.imagen);
     this.meta.addTag({ itemprop: 'image', content: seo.imagen });

     this.meta.addTag({ name: 'evisit-after', content: '1 days' });
     this.meta.addTag({ name: 'distribution', content: 'global' });
     this.meta.addTag({ name: 'format-detection', content: 'telephone=no' });

     //facebook
     this.meta.addTag({ property: 'og:locale', content: 'es_LA' });
     this.meta.addTag({ property: 'og:type', content: 'Organization' });
     this.meta.addTag({ property: 'og:title', content: this.decode_utf8( seo.titulo ) });
     this.meta.addTag({ property: 'og:description', content: this.decode_utf8( seo.descripcion ) });
     this.meta.addTag({ property: 'og:url', content: window.location.href });
     this.meta.addTag({ property: 'og:site_name', content: seo.tituloPortal });
     this.meta.addTag({ property: 'og:image', content: seo.imagen });

     this.meta.addTag({ property: 'fb:app_id', content: seo.fbAppid });

     //twitter
     this.meta.addTag({ name: 'twitter:card', content: 'summary' });
     this.meta.addTag({ name: 'twitter:site', content: seo.UrlPortal });
     this.meta.addTag({ name: 'twitter:title', content: this.decode_utf8( seo.titulo ) });
     this.meta.addTag({ name: 'twitter:description', content: this.decode_utf8( seo.descripcion ) });
     this.meta.addTag({ name: 'twitter:creator', content: seo.autor });


   }

   /**
    * Decodifica el utf8 recibido
    *
    * @param String    texto para decodificar
    */
   decode_utf8(s) {
     try{
         return decodeURIComponent(escape(s));
     }catch(err){
       return s.replace("Ã‰", "É");
     }
   }

}
