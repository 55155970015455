import { Component, OnInit,  ViewChild, Inject, ElementRef  } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer, Meta, Title } from '@angular/platform-browser';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

//provider
import { SedeProviderService } from '../../../providers/sedes/sede-provider.service';
import { CorreoProviderService } from '../../../providers/correos/correo-provider.service';

@Component({
  selector: 'app-semarca',
  templateUrl: './semarca.component.html',
  styleUrls: ['./semarca.component.css']
})
export class SemarcaComponent implements OnInit {

  public correo: any = {};
  public ciudades: any = [];
  public correoEnviar: any = "";
  public tipoCorreo: any = "";
  public sedes: any = [];
  public error: any = false;
  public mensaje: any = "";
  public sedeSelect: any = "";
  public ciudadSelect: any = "";

  constructor( private sedeProvider: SedeProviderService, private router: Router, private correoProvider: CorreoProviderService, private meta: Meta, private title: Title, @Inject(DOCUMENT) private document: any, private location: Location ) { }

  ngOnInit() {
     this.cargarDatos();
   }

   /**
    * Carga los del menu principal
    *
    */
   cargarDatos(){
     let datos = {};
     this.sedeProvider.consultaCiudadesSedes().subscribe(
       ( data ) => {
         let that = this;
         that.ciudades = data.datos;
       },
       ( error ) => {
       }
     );

   }
   /**
    * valida la informacion para el envio del formulario
    *
    * @returns Array [0] booleano con respuesta de validacion y [1]mensaje de error
    */
   validarInformacion(){
     let mensaje = "";
     let error = false;
     //validaciones
     if (  this.correo.nombre == undefined && this.correo.correo == undefined  && this.correo.ciudad == undefined && this.correo.sede == undefined  ){
       mensaje = "Los campos estan vacios";
       error = true;
     }else if( this.correo.nombre == undefined || this.correo.correo == undefined || this.correo.ciudad == undefined || this.correo.sede == undefined  ){
       mensaje = "los datos estan incompletos";
       error = true;
     }else if ( ! /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.correo.correo)){
       mensaje = "Direccion de correo no valido";
       error = true;
     }
     return [ error, mensaje ];
   }
  /**
  * Hace el envio del del correo
  *
  * @param Array   elementos del menu principal
  */
  enviarCorreo(){
    this.error = false;
    this.mensaje = "";
    let datos = {
      'tipoCorreo': 'portal',
      'correoEnviar': this.correoEnviar,
      'correo': this.correo
    };
    let validacion = this.validarInformacion();
    this.error = validacion;
    if( validacion[0] == false ){
      this.correoProvider.enviarSerMarca( datos ).subscribe(
        ( data ) => {
          let  that = this;
          that.error = true;
          that.mensaje = "El mensaje se envio correctamente";
          that.correo = {};
        },
        ( error ) => {
          let  that = this;
          that.error = true;
          that.mensaje = "Error al enviar el mensaje, vuelve a intentar mas tarde.";
        }
      );
    }else{
      this.mensaje = validacion[1];
    }
  }

  /**
   * Carga los datos de las sedes la ciudad seleccionada en "select"
   *
   * @param Array   elementos del menu principal
   */
  cargarSedes( index: any ){
    this.sedes = [];
    this.sedeSelect = "";
    this.correo.ciudad = this.ciudades[index].nombre;
    this.sedes = this.ciudades[index].sedes;
  }

  seleccionarSede( index ){
    this.correo.sede = this.sedes[index].nombre;
    this.correoEnviar = this.sedes[index].correo;
  }

  /**
   * Carga el contenido del seo de la pagina
   *
   * @param Object   datos del seo
   */
  cargarSeo( seo ){
     this.title.setTitle( 'Contacto | MUTALIS' );

     let link: HTMLLinkElement = this.document.createElement('link');
     link.setAttribute('rel', 'canonical');
     this.document.head.appendChild(link);
     link.setAttribute('href', window.location.href);

     this.meta.addTag({ name: 'robots', content: 'all' });
     this.meta.addTag({ name: 'description', content: 'Contacto Mutalis' });
     this.meta.addTag({ name: 'keywords', content: 'contacto' });
     this.meta.addTag({ name: 'author', content: seo.autor });

     this.meta.addTag({ itemprop: 'name', content: seo.tituloPortal});
     this.meta.addTag({ itemprop: 'url', content: seo.UrlPortal });
     this.meta.addTag({ itemprop: 'thumbnailUrl', content: seo.imagen });
     let link2: HTMLLinkElement = this.document.createElement('link');
     link2.setAttribute('rel', 'image_src');
     this.document.head.appendChild(link2);
     link2.setAttribute('href', seo.imagen);
     this.meta.addTag({ itemprop: 'image', content: seo.imagen });

     this.meta.addTag({ name: 'evisit-after', content: '1 days' });
     this.meta.addTag({ name: 'distribution', content: 'global' });
     this.meta.addTag({ name: 'format-detection', content: 'telephone=no' });

     //facebook
     this.meta.addTag({ property: 'og:locale', content: 'es_LA' });
     this.meta.addTag({ property: 'og:type', content: 'Organization' });
     this.meta.addTag({ property: 'og:title', content: 'Contacto | MUTALIS' });
     this.meta.addTag({ property: 'og:description', content: 'Contacto MUTALIS' });
     this.meta.addTag({ property: 'og:url', content: window.location.href });
     this.meta.addTag({ property: 'og:site_name', content: seo.tituloPortal });
     this.meta.addTag({ property: 'og:image', content: seo.imagen });

     this.meta.addTag({ property: 'fb:app_id', content: seo.fbAppid });

     //twitter
     this.meta.addTag({ name: 'twitter:card', content: 'summary' });
     this.meta.addTag({ name: 'twitter:site', content: seo.UrlPortal });
     this.meta.addTag({ name: 'twitter:title', content: this.decode_utf8( seo.titulo )  });
     this.meta.addTag({ name: 'twitter:description', content: this.decode_utf8( seo.descripcion )  });
     this.meta.addTag({ name: 'twitter:creator', content: seo.autor });
	}
  /**
   * Decodifica el utf8 recibido
   *
   * @param String    texto para decodificar
   */
  decode_utf8(s) {
    try{
        return decodeURIComponent(escape(s));
    }catch(err){
      return s.replace("Ã‰", "É");
    }
  }
}
