import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

//provider
import { MenuPrincipalProviderService } from '../../../providers/menus/menuPrincipal/menu-principal-provider.service';


@Component({
  selector: 'app-menu-superior',
  templateUrl: './menu-superior.component.html',
  styleUrls: ['./menu-superior.component.css']
})
export class MenuSuperiorComponent implements OnInit {

    public nodos: any = [];

    constructor( private MenuPrincipalProvider: MenuPrincipalProviderService,  private router: Router ){

    }

    ngOnInit() {
      this.cargarDatos();
    }


    /**
     * Carga los del menu principal
     *
     */
    cargarDatos(){
      this.MenuPrincipalProvider.consultaMenuPrincipal().subscribe(
        ( data ) => {
          let that = this;
          that.cargarElementosMenuPrincipal( data.datos );
        },
        ( error ) =>{
          console.log( error );
        }
      );
    }


    /**
     * Carga los datos menu principal
     *
     * @param Array   elementos del menu principal
     */
    cargarElementosMenuPrincipal( nodos: any ){
      this.nodos = nodos;
    }

    /**
     * Decodifica el utf8 recibido
     *
     * @param String    texto para decodificar
     */
    decode_utf8(s) {
      try{
          return decodeURIComponent(escape(s));
      }catch(err){
        return s.replace("Ã‰", "É");
      }
    }

}
