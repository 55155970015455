import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';

//providers
import { SedeProviderService } from '../../../providers/sedes/sede-provider.service';
import { CorreoProviderService } from '../../../providers/correos/correo-provider.service';

@Component({
  selector: 'app-nuestra-sede',
  templateUrl: './nuestra-sede.component.html',
  styleUrls: ['./nuestra-sede.component.css']
})
export class NuestraSedeComponent implements OnInit {

  public urlSede: any;
  public contenido: any;
  public sede: any = {};
  public _opened: boolean = false;
  public correoModal:  any = {};
  public errorCorreo: any = false;
  public mensajeCorreo: any = "";


  constructor( private sedeProvider: SedeProviderService, private router: Router, private activateRoute: ActivatedRoute, private sanitizer: DomSanitizer, private correoProvider: CorreoProviderService ) { }
  private _toggleSidebar() {
    this._opened = !this._opened;
  }
  ngOnInit() {
    this.activateRoute.paramMap.subscribe(
      ( map ) => {
        let that = this;
        that.urlSede = map.get('url');
        that.cargarDatos( that.urlSede );
      }
    );
  }

  /**
   * Obtiene la informacion de la pagina
   *
   * @param String   la url de la sede 'sede/UrlSede'
   */
   cargarDatos( url: any ){
     let urlcompleta = "/"+url;
     let datos = {
      url: urlcompleta,
    }
     this.sedeProvider.consultaDatosSede( datos ).subscribe(
       ( data ) => {
         let that = this;
         that.sede = data.datos;
         that.correoModal.sede = data.datos.nombre;
         that.correoModal.ciudad = data.datos.ciudad;
         that.cargarContenido( data.datos.contenido );
       },
       ( error ) => {
         if( error.status == 500 ){
           console.log( "error en el servidor");
         }
       }
     );
   }

   /**
    * Carga el contenido de la pagina que retorna el servidor
    *
    * @param String   codigo html almacenado en la base de datos
    */
   cargarContenido( contenido ){
     this.contenido = this.sanitizer.bypassSecurityTrustHtml( contenido );
   }

   /**
    * valida la informacion para el envio del formulario
    *
    * @returns Array [0] booleano con respuesta de validacion y [1]mensaje de error
    */
   validarInformacion(){
     let mensaje = "";
     let error = false;
     //validaciones
     if (  this.correoModal.nombre == undefined && this.correoModal.correo == undefined && this.correoModal.telefono == undefined  && this.correoModal.mensaje == undefined ){
       mensaje = "Los campos estan vacios";
       error = true;
     }else if( this.correoModal.nombre == undefined || this.correoModal.correo == undefined  || this.correoModal.telefono == undefined  || this.correoModal.mensaje == undefined ){
       mensaje = "los datos estan incompletos";
       error = true;
     }else if ( ! /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.correoModal.correo)){
       mensaje = "Direccion de correo no valido";
       error = true;
     }
     return [ error, mensaje ];
   }

   /**
    * Hace el envio del del correo
    *
    * @param Array   elementos del menu principal
    */

   enviarCorreo(){
     this.errorCorreo = false;
     this.mensajeCorreo = "";
     let validacion = this.validarInformacion();
     if( validacion[0] == false ){
       let datos = {
         'tipoCorreo': 'sede',
         'correoEnviar': this.sede.correo,
         'correo': this.correoModal
       };
       this.correoProvider.enviarCorreoContacto( datos ).subscribe(
         ( data ) => {
           this.errorCorreo = true;
           this.mensajeCorreo = "El mensaje se envio correctamente";
           this.correoModal.nombre = undefined;
           this.correoModal.correo = undefined;
           this.correoModal.telefono = undefined;
           this.correoModal.mensaje = undefined;
         },
         ( error ) => {
           this.errorCorreo = true;
           this.mensajeCorreo = "Error al enviar el mensaje, vuelve a intentar mas tarde.";
         }
       );
     }else{
       this.errorCorreo = true;
       this.mensajeCorreo = validacion[1];
     }
   }

   /**
    * Decodifica el utf8 recibido
    *
    * @param String    texto para decodificar
    */
   decode_utf8(s) {
     try{
         return decodeURIComponent(escape(s));
     }catch(err){
       return s.replace("Ã‰", "É");
     }
   }

}
