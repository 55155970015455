import {Component, OnInit, Inject,ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';

//providers
import { PartnersService } from '../../../providers/partners/partners.service';

@Component({
  selector: 'app-campanas',
  templateUrl: './campanas.component.html',
  styleUrls: ['./campanas.component.css']
})
export class CampanasComponent implements OnInit {

  mode = new FormControl('over');
  public idUsuario: any;
  public token: any;
  public campanas: any = [];
  public campana: any = {};
  public pagina: any = 1;
  public contenido: any;
  public cantidadPaginas: any;



  constructor( private partnerProvider: PartnersService , private router: Router, private activateRoute: ActivatedRoute, private sanitizer: DomSanitizer, private meta: Meta, private title: Title, @Inject(DOCUMENT) private document: any, public snackBar: MatSnackBar ){
  }

  ngOnInit() {
    this.token = this.obtenerToken();
    if( this.token == false ){
      this.mostrarAlerta('La sesión ha expirado, vuelve a iniciar sesión.', 8000);
      this.logOut();
    }
    this.cargarDatos( this.token, this.idUsuario, this.pagina );
  }

  /**
   * Obtiene el token almacenado en el localStorage
   *
   * @returns String con la cadena del token o Booleano si no hay sesion abierta
   */
  obtenerToken(){
    let salida: any;
    let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
    if( datos.sesion == 0 ){
      salida = false;
    }else{
      salida = datos.token;
      this.idUsuario = datos.idUsuario;
    }
    return salida;
  }

  /**
   * Carga los dato
   *
   * @param String   token almacenado en  localStorage
   * @param Integer   id de la sede logueada
   * @param Object   datos de la noticia
   *
   */
  cargarDatos( token: any, idSede: any, pagina: any ){
    let datos = {
      idSede: idSede,
      pagina: pagina
    };
    this.partnerProvider.consultarCampanas( token, datos ).subscribe(
      ( data ) => {
        let that = this;
        let datos = data.datos;
        that.campanas = datos.campanas;
        that.cantidadPaginas = datos.paginas;
        that.verCampana( that.campanas[0].idCampana);
      },
      ( error ) => {
        if( error.status == 401){
           this.mostrarAlerta('La sesión ha expirado, vuelve a iniciar sesión.', 8000);
           this.logOut();
         }
         if( error.status == 500){
           this.mostrarAlerta('No se pudo actualizar la información.', 8000);
         }
      }
    );

  }

  /**
   * Carga la noticia que se visulizara
   *
   * @param Integer   id de la noticia que se visualizara
   */
  verCampana( idCampana ){
    let datos = {
      idCampana: idCampana
    };

    this.partnerProvider.consultarCampanaId( this.token, datos ).subscribe(
      ( data ) => {
        let that = this;
        let datos = data.datos;
        that.campana = datos;
        that.contenido = this.sanitizer.bypassSecurityTrustHtml(datos.descripcion);
      },
      ( error ) => {
        if( error.status == 401){
           this.mostrarAlerta('La sesión ha expirado, vuelve a iniciar sesión.', 8000);
           this.logOut();
         }
         if( error.status == 500){
           this.mostrarAlerta('No se pudo actualizar la información.', 8000);
         }
      }
    );
  }

  /**
   * Carga los dato
   *
   * @param String   token almacenado en  localStorage
   * @param Integer   id de la sede logueada
   * @param Object   datos de la noticia
   *
   */
   siguiente(){
     this.pagina = this.pagina + 1;
     this.cargarDatosPaginador( this.token, this.idUsuario, this.pagina );
   }

   /**
    * Carga los dato
    *
    * @param String   token almacenado en  localStorage
    * @param Integer   id de la sede logueada
    * @param Object   datos de la noticia
    *
    */
    anterior(){
      this.pagina = this.pagina - 1;
      this.cargarDatosPaginador( this.token, this.idUsuario, this.pagina );
    }

    /**
     * Carga los dato
     *
     * @param String   token almacenado en  localStorage
     * @param Integer   id de la sede logueada
     * @param Integer   pagina a cargar
     *
     */
    cargarDatosPaginador( token: any, idSede: any, pagina: any ){
      let datos = {
        idSede: idSede,
        pagina: pagina
      };
      this.partnerProvider.consultarCampanas( token, datos ).subscribe(
        ( data ) => {
          let that = this;
          let datos = data.datos;
          that.campanas = datos.campanas;
          that.cantidadPaginas = datos.paginas;
        },
        ( error ) => {
          if( error.status == 401){
             this.mostrarAlerta('La sesión ha expirado, vuelve a iniciar sesión.', 8000);
             this.logOut();
           }
           if( error.status == 500){
             this.mostrarAlerta('No se pudo actualizar la información.', 8000);
           }
        }
      );
    }

    /**
     * Decodifica el utf8 recibido
     *
     * @param String    texto para decodificar
     */
    decode_utf8(s) {
      try{
          return decodeURIComponent(escape(s));
      }catch(err){
        return s.replace("Ã‰", "É");
      }
    }

  /**
   * Realiza el cierre de sesion
   *
   * @returns hace el cierre de sesion y redirecciona al login
   */
  logOut(){
    let datos = {
      sesion: 0
    }
    localStorage.setItem( 'sesion', JSON.stringify( datos ) );
    location.href='/inicio';
  }

  /**
 * Genera una alerta para el usuario
 *
 * @param String mensaje que se va a mostrar
 * @param Integer tiempo en milisegundos
 *
 * @returns Muesta la alerta en la parte inferior de la pantalla
 */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }
}
