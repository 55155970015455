import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ENV } from '../../../../conf/ENV';
import { ResponseInformationObjectInterface } from '../../../wrappers/response-information-object-interface';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PaginaProviderService {

  public environment: any;
  public configUrl: any;

  constructor( private http: HttpClient, private env: ENV) {
    this.environment = env.getEnv();
    this.configUrl = this.environment.API_URL + "/paginas/";
  }

  /**
   * Envia la solicitud a la Api para la consultar el contenido de la pagina
   *
  * @param object Datos del menu principal
   * @returns respuesta del servidor o mensaje de error
   */
  consultaContenidoPagina( datos:any ){
    let accion = 'consultaPagina/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
   * Envia la solicitud a la Api consultar la configuracion del portal
   *
   * @returns respuesta del servidor o mensaje de error
   */
  consultarConfiguracionPortal(  ){
    let accion = 'configuracionPortal/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, options );
  }

  /**
   * Envia la solicitud a la Api para consultar los convenios del portal
   *
  * @param object
   * @returns respuesta del servidor o mensaje de error
   */
  consultarConvenios( datos:any ){
    let accion = 'consultarConvenios/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }
}
