import { Component, OnInit,  ViewChild, Inject, ElementRef  } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer, Meta, Title } from '@angular/platform-browser';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

//provider
import { SedeProviderService } from '../../providers/sedes/sede-provider.service';
import { CorreoProviderService } from '../../providers/correos/correo-provider.service';
import { PaginaProviderService } from '../../providers/paginas/paginas/pagina-provider.service';

@Component({
  selector: 'app-sugerencias',
  templateUrl: './sugerencias.component.html',
  styleUrls: ['./sugerencias.component.css']
})
export class SugerenciasComponent implements OnInit {

  public sugerencia: any = {};
  public ciudades: any = [];
  public sedes: any = [];
  public error: any = false;
  public mensaje: any = "";
  public enviandoCorreo: any = false;

  constructor( private sedeProvider: SedeProviderService, private router: Router, private correoProvider: CorreoProviderService, private PaginaService: PaginaProviderService, private meta: Meta, private title: Title, @Inject(DOCUMENT) private document: any, private location: Location ) { }

  ngOnInit() {
    this.cargarDatos();
  }

  /**
   * Carga los del menu principal
   *
   */
  cargarDatos(){
    let datos = {};

    //carga de algunos datos del seo
    this.PaginaService.consultarConvenios( datos ).subscribe(
      ( data ) => {
        let that = this;
        let datos = data.datos;
        that.cargarSeo( datos.seo );
      },
      ( error ) => {
      }
    );
  }

  /**
   * valida la informacion para el envio del formulario
   *
   * @returns Array [0] booleano con respuesta de validacion y [1]mensaje de error
   */
  validarInformacion(){
    let mensaje = "";
    let error = false;
    //validaciones
    if (  this.sugerencia.nombre == undefined && this.sugerencia.correo == undefined && this.sugerencia.tipo == undefined && this.sugerencia.mensaje == undefined ){
      mensaje = "Los campos estan vacios";
      error = true;
    }else if (  this.sugerencia.nombre == "" && this.sugerencia.correo == "" && this.sugerencia.tipo == "" && this.sugerencia.mensaje == "" ){
        mensaje = "Los campos estan vacios";
        error = true;
      }else if( this.sugerencia.nombre == undefined || this.sugerencia.correo == undefined || this.sugerencia.tipo == undefined || this.sugerencia.mensaje == undefined ){
      mensaje = "los datos estan incompletos";
      error = true;
    }else if ( ! /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.sugerencia.correo)){
      mensaje = "Direccion de correo no valido";
      error = true;
    }
    return [ error, mensaje ];
  }

  /**
   * Envia la solicitud a la Api para almacenar la sugerencia
   *
   * @returns respuesta del servidor o mensaje de error
   */
  enviarSugerencia(){
    this.error = false;
    this.mensaje = "";
    let validacion = this.validarInformacion();
    this.error = validacion
    if( validacion[0] == false ){
      this.mensaje = "Enviando";
      this.enviandoCorreo = true;
      this.correoProvider.enviarSugerencia( this.sugerencia ).subscribe(
        ( data ) => {
          let  that = this;
          that.error = true;
          that.mensaje = "El mensaje se envio correctamente";
          that.sugerencia = {};
          this.enviandoCorreo = false;
        },
        ( error ) => {
          let  that = this;
          that.error = true;
          that.mensaje = "Error al enviar el mensaje, vuelve a intentar mas tarde.";
          this.enviandoCorreo = false;
        }
      );
    }else{
      this.mensaje = validacion[1];
    }

  }


  
  /**
   * Carga el contenido del seo de la pagina
   *
   * @param Object   datos del seo
   */
   cargarSeo( seo ){
     this.title.setTitle( 'Sugerencias, quejas y reclamos || Mutalis' );

     let link: HTMLLinkElement = this.document.createElement('link');
     link.setAttribute('rel', 'canonical');
     this.document.head.appendChild(link);
     link.setAttribute('href', window.location.href);

     this.meta.addTag({ name: 'robots', content: 'all' });
     this.meta.addTag({ name: 'description', content: 'Envia tus felicitaciones, sugerencias, quejas o reclamos' });
     this.meta.addTag({ name: 'keywords', content: 'sugerencias, quejas, reclamos, felicitacion, Mutalis' });
     this.meta.addTag({ name: 'author', content: seo.autor });

     this.meta.addTag({ itemprop: 'name', content: seo.tituloPortal});
     this.meta.addTag({ itemprop: 'url', content: seo.UrlPortal });
     this.meta.addTag({ itemprop: 'thumbnailUrl', content: seo.imagen });
     let link2: HTMLLinkElement = this.document.createElement('link');
     link2.setAttribute('rel', 'image_src');
     this.document.head.appendChild(link2);
     link2.setAttribute('href', seo.imagen);
     this.meta.addTag({ itemprop: 'image', content: seo.imagen });

     this.meta.addTag({ name: 'evisit-after', content: '1 days' });
     this.meta.addTag({ name: 'distribution', content: 'global' });
     this.meta.addTag({ name: 'format-detection', content: 'telephone=no' });

     //facebook
     this.meta.addTag({ property: 'og:locale', content: 'es_LA' });
     this.meta.addTag({ property: 'og:type', content: 'Organization' });
     this.meta.addTag({ property: 'og:title', content: 'Sugerencias, quejas y reclamos || Mutalis' });
     this.meta.addTag({ property: 'og:description', content: 'Envia tus felicitaciones, sugerencias, quejas o reclamos' });
     this.meta.addTag({ property: 'og:url', content: window.location.href });
     this.meta.addTag({ property: 'og:site_name', content: seo.tituloPortal });
     this.meta.addTag({ property: 'og:image', content: seo.imagen });

     this.meta.addTag({ property: 'fb:app_id', content: seo.fbAppid });

     //twitter
     this.meta.addTag({ name: 'twitter:card', content: 'summary' });
     this.meta.addTag({ name: 'twitter:site', content: seo.UrlPortal });
     this.meta.addTag({ name: 'twitter:title', content: seo.titulo });
     this.meta.addTag({ name: 'twitter:description', content: seo.descripcion });
     this.meta.addTag({ name: 'twitter:creator', content: seo.autor });


   }

}
