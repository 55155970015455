import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';

//providers
import { SedeProviderService } from '../../../providers/sedes/sede-provider.service';

@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.component.html',
  styleUrls: ['./promociones.component.css']
})
export class PromocionesComponent implements OnInit {

  public contenido: any = "";
  public urlSede: any = "";
  constructor( private sedeProvider: SedeProviderService, private router: Router, private activateRoute: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.activateRoute.paramMap.subscribe(
      ( map ) => {
        let that = this;
        that.urlSede = map.get('url');
        that.cargarDatos( that.urlSede );
      }
    );
  }

  /**
   * Obtiene la informacion de la pagina
   *
   * @param String   la url de la sede 'sede/UrlSede'
   */
   cargarDatos( url: any ){
     let urlcompleta = "/"+url;
     let datos = {
      url: urlcompleta,
    }
     this.sedeProvider.consultaPromocionesSede( datos ).subscribe(
       ( data ) => {
         let that = this;
         that.cargarContenido( data.datos );
       },
       ( error ) => {
         if( error.status == 500 ){
           console.log( "error en el servidor");
         }
       }
     );
   }

   /**
    * Carga el contenido de la pagina que retorna el servidor
    *
    * @param String   codigo html almacenado en la base de datos
    */
   cargarContenido( contenido ){
     this.contenido = this.sanitizer.bypassSecurityTrustHtml( contenido );
   }


}
