import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';

//providers
import { NoticiasService } from '../../../providers/noticias/noticias.service';

@Component({
  selector: 'app-lista-noticias',
  templateUrl: './lista-noticias.component.html',
  styleUrls: ['./lista-noticias.component.css']
})
export class ListaNoticiasComponent implements OnInit {

  public noticias: any = [];


  constructor( private noticiaProvider: NoticiasService, private router: Router, private activateRoute: ActivatedRoute, private sanitizer: DomSanitizer, private meta: Meta, private title: Title, @Inject(DOCUMENT) private document: any  ) {
  }

  ngOnInit() {
    this.cargarDatos();
  }

  /**
   * Obtiene la informacion de la pagina
   *
   * @param String   la url de la pagina 'web/Urlpagina'
   */
  cargarDatos( ){
    //trae la lista de noticias para el owl
    this.noticiaProvider.consultarNoticias().subscribe(
      ( data ) => {
        let datos = data.datos;
        let that = this;
        that.noticias = data.datos;
        console.log( that.noticias );
        that.cargarSeo();
      },
      ( error ) => {
        console.log( error );
      }
    );
  }

  /**
   * Carga el contenido del seo de la noticia
   *
   * @param Object   datos del seo
   */
  cargarSeo(  ){
    this.title.setTitle( 'Blog | MUTALIS' );

    let link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', window.location.href);


    this.meta.addTag({ name: 'robots', content: 'all' });
    this.meta.addTag({ name: 'description', content: this.decode_utf8( 'BlOG  Mutalis' )  });
    this.meta.addTag({ name: 'keywords', content: this.decode_utf8( 'BlOG, Mutalis' ) });
    this.meta.addTag({ name: 'author', content: 'Mutalis' });

    this.meta.addTag({ itemprop: 'name', content: 'Mutalis'});
    this.meta.addTag({ itemprop: 'url', content: window.location.href });
    this.meta.addTag({ itemprop: 'thumbnailUrl', content: 'favicon.ico' });
    let link2: HTMLLinkElement = this.document.createElement('link');
    link2.setAttribute('rel', 'image_src');
    this.document.head.appendChild(link2);
    link2.setAttribute('href', 'favicon.ico');
    this.meta.addTag({ itemprop: 'image', content: 'favicon.ico' });

    this.meta.addTag({ name: 'evisit-after', content: '1 days' });
    this.meta.addTag({ name: 'distribution', content: 'global' });
    this.meta.addTag({ name: 'format-detection', content: 'telephone=no' });

    //facebook
    this.meta.addTag({ property: 'og:locale', content: 'es_LA' });
    this.meta.addTag({ property: 'og:type', content: 'Organization' });
    this.meta.addTag({ property: 'og:title', content: this.decode_utf8( 'Blog | MUTALIS' ) });
    this.meta.addTag({ property: 'og:description', content: this.decode_utf8( 'Blog | MUTALIS' ) });
    this.meta.addTag({ property: 'og:url', content: window.location.href });
    this.meta.addTag({ property: 'og:site_name', content: 'Mutalis' });
    this.meta.addTag({ property: 'og:image', content: 'favicon.ico'});

    this.meta.addTag({ property: 'fb:app_id', content: '' });

    //twitter
    this.meta.addTag({ name: 'twitter:card', content: 'summary' });
    this.meta.addTag({ name: 'twitter:site', content: window.location.href });
    this.meta.addTag({ name: 'twitter:title', content: this.decode_utf8( 'Blog | MUTALIS' ) });
    this.meta.addTag({ name: 'twitter:description', content: this.decode_utf8( 'Blog | MUTALIS' ) });
    this.meta.addTag({ name: 'twitter:creator', content: 'Mutalis' });


  }

  /**
   * Decodifica el utf8 recibido
   *
   * @param String    texto para decodificar
   */
  decode_utf8(s) {
    try{
        return decodeURIComponent(escape(s));
    }catch(err){
      return s.replace("Ã‰", "É");
    }
  }
}
