import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';

//provider
import { BusquedaProviderService } from '../../providers/busqueda/busqueda-provider.service';

@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.css']
})
export class BusquedaComponent implements OnInit {

  private busqueda: any = "";
  public resultados: any = [];
  public validarResultados: any = false;

  constructor( private router: Router, private activateRoute: ActivatedRoute, private busquedaProvider: BusquedaProviderService, private sanitizer: DomSanitizer ) { }

  ngOnInit() {
    this.activateRoute.paramMap.subscribe(
      ( map ) => {
        let that = this;
        that.busqueda = map.get('busqueda');
        that.cargarDatos( that.busqueda  );
      }
    );
  }

  /**
  * Obtiene los resultados de la busqueda
  *
  * @param String   busqueda
  */
  cargarDatos( busqueda: any ){
    let datos = {
      busqueda: busqueda
    };

    this.busquedaProvider.buscarContenido( datos ).subscribe(
      ( data ) => {
        let datos = data.datos;
        console.log( datos );
        let that = this;
        if( datos.length >= 1 ){
          that.validarResultados = true;
          that.resultados = datos;
        }
      },
      ( error ) => {

      }
    );

  }

  /**
  * Cura la cadena para inyectarla de forma segura al html
  *
  * @param String   contenido
  */
  curarCodigo( string ){
    return this.sanitizer.bypassSecurityTrustHtml( string );
  }
  
}
