import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

//providers
import { SedeProviderService } from '../../../providers/sedes/sede-provider.service';
import { CorreoProviderService } from '../../../providers/correos/correo-provider.service';
@Component({
  selector: 'app-contactenos',
  templateUrl: './contactenos.component.html',
  styleUrls: ['./contactenos.component.css']
})
export class ContactenosComponent implements OnInit {

  public urlSede: any = "";
  public datosSede : any = {};
  public correoSede: any = "";
  public correo: any = {};
  public error: any = false;
  public mensaje: any = "";

  constructor( private sedeProvider: SedeProviderService, private router: Router, private activateRoute: ActivatedRoute, private correoProvider: CorreoProviderService ) { }

  ngOnInit() {
    this.activateRoute.paramMap.subscribe(
      ( map ) => {
        let that = this;
        that.urlSede = map.get('url');
        that.cargarDatos( that.urlSede );
      }
    );
  }
  /**
   * Obtiene la informacion de la pagina
   *
   * @param String   la url de la pagina 'web/Urlpagina'
   */
   cargarDatos( url: any ){
     let urlcompleta = "/"+url;
     let datos = {
      url: urlcompleta,
      }
      this.sedeProvider.consultaDatosSede( datos ).subscribe(
        ( data ) => {
          let that = this;
          that.cargarInformacion( data.datos );
        },
        ( error ) => {
          if( error.status == 500 ){
            console.log( "error en el servidor");
          }
        }
      );

   }

   /**
    * Carga el los datos principales de la sede
    *
    * @param Object   informacion de la sede
    */
   cargarInformacion ( datos ){
     this.datosSede = datos;
     this.correoSede = datos.correo;
   }

   /**
    * Hace el envio del del correo
    *
    * @param Array   elementos del menu principal
    */

   enviarCorreo(){
     let datos = {
       'tipoCorreo': 'sede',
       'correoEnviar': this.correoSede,
       'correo': this.correo
     };
     let validacion = this.validarInformacion();
     this.error = validacion;
     if( validacion[0] == false ){
       this.correoProvider.enviarCorreoContacto( datos ).subscribe(
         ( data ) => {
           let  that = this;
           that.error = true;
           that.mensaje = "El mensaje se envio correctamente";
           this.correo.nombre = undefined;
           this.correo.correo = undefined;
           this.correo.telefono = undefined;
           this.correo.mensaje = undefined;
         },
         ( error ) => {
           let  that = this;
           that.error = true;
           that.mensaje = "Error al enviar el mensaje, vuelve a intentar mas tarde.";
         }
       );
     }else{
       this.mensaje = validacion[1];
     }
   }

   /**
    * valida la informacion para el envio del formulario
    *
    * @returns Array [0] booleano con respuesta de validacion y [1]mensaje de error
    */
   validarInformacion(){
     let mensaje = "";
     let error = false;
     //validaciones
     if (  this.correo.nombre == undefined && this.correo.correo == undefined && this.correo.telefono == undefined  && this.correo.mensaje == undefined ){
       mensaje = "Los campos estan vacios";
       error = true;
     }else if( this.correo.nombre == undefined || this.correo.correo == undefined  || this.correo.telefono == undefined  || this.correo.mensaje == undefined ){
       mensaje = "los datos estan incompletos";
       error = true;
     }else if ( ! /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.correo.correo)){
       mensaje = "Direccion de correo no valido";
       error = true;
     }
     return [ error, mensaje ];
   }

   /**
    * Decodifica el utf8 recibido
    *
    * @param String    texto para decodificar
    */
   decode_utf8(s) {
     try{
         return decodeURIComponent(escape(s));
     }catch(err){
       return s.replace("Ã‰", "É");
     }
   }
}
