import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ENV } from '../../../conf/ENV';
import { ResponseInformationObjectInterface } from '../../wrappers/response-information-object-interface';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CorreoProviderService {

  public environment: any;
  public configUrl: any;

  constructor( private http: HttpClient, private env: ENV ) {
    this.environment = env.getEnv();
    this.configUrl = this.environment.API_URL + "/correo/";
  }

  /**
   * Envia la solicitud a la Api para el envio de los correos de contacto
   *
   * @param object los datos del mensaje
   * @returns respuesta del servidor o mensaje de error
   */
  enviarCorreoContacto( datos:any ){
    let accion = 'enviarCorreoContacto/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
   * Envia la solicitud a la Api para enviar las sugerencias quejas y reclamos
   *
   * @param object los datos de las sugerencia
   * @returns respuesta del servidor o mensaje de error
   */
  enviarSugerencia( datos:any ){
    let accion = 'enviarSugerencia/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
   * Envia la solicitud a la Api realizar la suscripcion al newsletter
   *
   * @param object los datos del correo Electronico
   * @returns respuesta del servidor o mensaje de error
   */
  enviarNewsLetter( datos:any ){
    let accion = 'enviarNewsLetter/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
   * Envia la solicitud a la Api realizar la registro del cliente para campañas
   *
   * @param object los datos del correo Electronico
   * @returns respuesta del servidor o mensaje de error
   */
  enviarSerMarca( datos: any ){
    let accion = 'enviarSerMarca/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }
}
