import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';

//providers
import { SedeProviderService } from '../../../providers/sedes/sede-provider.service';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {

  public profesionales: any = [];
  public urlSede: any = "";
  public nombreSede: any = "";
  public idSede: any;

  constructor( private sedeProvider: SedeProviderService, private router: Router, private activateRoute: ActivatedRoute ){
  }

  ngOnInit() {
    this.activateRoute.paramMap.subscribe(
      ( map ) => {
        let that = this;
        that.urlSede = map.get('url');
        that.cargarDatos( that.urlSede );
      }
    );
  }

  /**
   * Obtiene la informacion de la pagina
   *
   * @param String   la url de la sede 'sede/UrlSede'
   */
   cargarDatos( url: any ){
     let urlcompleta = "/"+url;
     let datos = {
      url: urlcompleta,
    }
     this.sedeProvider.consultarProfesionales( datos ).subscribe(
       ( data ) => {
         let that = this;
         let respuesta = data.datos;
         that.cargarProfesionales( respuesta.profesionales );
         that.nombreSede = respuesta.sede;
         that.idSede = respuesta.idSede;

       },
       ( error ) => {
         if( error.status == 500 ){
           console.log( "error en el servidor");
         }
       }
     );
   }

   /**
    * Carga los datos de los profesional
    *
    * @param Array   los profesionales asociados a la sede
    */
   cargarProfesionales( profesionales: any ){
     this.profesionales = profesionales;
   }
   /**
    * Decodifica el utf8 recibido
    *
    * @param String    texto para decodificar
    */
   decode_utf8(s) {
     try{
         return decodeURIComponent(escape(s));
     }catch(err){
       return s.replace("Ã‰", "É");
     }
   }
}
