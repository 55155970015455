import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

//provider
import { MenuPrincipalProviderService } from '../../providers/menus/menuPrincipal/menu-principal-provider.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Output() app = new EventEmitter();
  cierre: any = null;
  public nodos: any = [];

  constructor( private MenuPrincipalProvider: MenuPrincipalProviderService,  private router: Router ){

  }

  ngOnInit() {
    this.cargarDatos();
  }

  cerrarMenu(){
    this.cierre = false;
    this.app.emit( this.cierre );
  }
  /**
   * Carga los del menu principal
   *
   */
  cargarDatos(){
    this.MenuPrincipalProvider.consultaMenuPrincipal().subscribe(
      ( data ) => {
        let that = this;
        that.cargarElementosMenuPrincipal( data.datos );
      },
      ( error ) =>{
        console.log( error );
      }
    );
  }


  /**
   * Carga los datos menu principal
   *
   * @param Array   elementos del menu principal
   */
  cargarElementosMenuPrincipal( nodos: any ){
    this.nodos = nodos;
  }

  /**
   * Decodifica el utf8 recibido
   *
   * @param String    texto para decodificar
   */
  decode_utf8(s) {
    try{
        return decodeURIComponent(escape(s));
    }catch(err){
      return s.replace("Ã‰", "É");
    }
  }

}
