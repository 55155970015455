import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ENV } from '../../../conf/ENV';
import { ResponseInformationObjectInterface } from '../../wrappers/response-information-object-interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnersService {

  public environment: any;
  public configUrl: any;

  constructor( private http: HttpClient, private env: ENV ) {
    this.environment = env.getEnv();
    this.configUrl = this.environment.API_URL + "/partner/";
  }

  /*--------------------------------- USUARIOS ------------------------------------*/
    /**
     * Envia la solicitud a la Api para el inicio de sesion
     *
     * @returns respuesta del servidor o mensaje de error
     */
    iniciarSesion( datos: any ){
      let accion = 'iniciarSesion/';
      let headers = new HttpHeaders({
        'content-type': 'application/json',
      });
      var options =  {
          headers: headers
      };
      return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
    }

    /**
     * Envia la solicitud a la Api para consultar los datos del usuario logueado
     *
     * @returns respuesta del servidor o mensaje de error
     */
    consultarUsuario( token: any, datos: any ){
      let accion = 'consultarUsuario/';
      let headers = new HttpHeaders({
        'content-type': 'application/json',
        'Authorization': 'Bearer '+token,
      });
      var options =  {
          headers: headers
      };
      return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
    }
  /*-------------------------------- /USUARIOS ------------------------------------*/

  /*--------------------------------- NOTICIAS ------------------------------------*/

    /**
     * Envia la solicitud a la Api para consultar los datos las noticias
     *
     * @returns respuesta del servidor o mensaje de error
     */
    consultarNoticias( token: any, datos: any ){
      let accion = 'noticias/consultaNoticias/';
      let headers = new HttpHeaders({
        'content-type': 'application/json',
        'Authorization': 'Bearer '+token,
      });
      var options =  {
          headers: headers
      };
      return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
    }

    /**
     * Envia la solicitud a la Api para consultar los datos de la noticia asociada al id
     *
     * @returns respuesta del servidor o mensaje de error
     */
    consultarNoticiaId( token: any, datos: any ){
      let accion = 'noticias/consultaNoticiaId/';
      let headers = new HttpHeaders({
        'content-type': 'application/json',
        'Authorization': 'Bearer '+token,
      });
      var options =  {
          headers: headers
      };
      return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
    }
  /*-------------------------------- /NOTICIAS ------------------------------------*/

  /*--------------------------------- CONVENIOS ------------------------------------*/
    /**
     * Envia la solicitud a la Api para consultar los datos de los convenios
     *
     * @returns respuesta del servidor o mensaje de error
     */
    consultarConvenios( token: any, datos: any ){
      let accion = 'convenios/consultarConvenios/';
      let headers = new HttpHeaders({
        'content-type': 'application/json',
        'Authorization': 'Bearer '+token,
      });
      var options =  {
          headers: headers
      };
      return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
    }

    /**
     * Envia la solicitud a la Api para consultar los datos del convenio asociado al id
     *
     * @returns respuesta del servidor o mensaje de error
     */
    consultarConvenioId( token: any, datos: any ){
      let accion = 'convenios/consultaConvenioId/';
      let headers = new HttpHeaders({
        'content-type': 'application/json',
        'Authorization': 'Bearer '+token,
      });
      var options =  {
          headers: headers
      };
      return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
    }

  /*-------------------------------- /CONVENIOS ------------------------------------*/

  /*--------------------------------- CAMPAÑAS ------------------------------------*/
    /**
     * Envia la solicitud a la Api para consultar los datos de los convenios
     *
     * @returns respuesta del servidor o mensaje de error
     */
    consultarCampanas( token: any, datos: any ){
      let accion = 'campanas/consultarCampanas/';
      let headers = new HttpHeaders({
        'content-type': 'application/json',
        'Authorization': 'Bearer '+token,
      });
      var options =  {
          headers: headers
      };
      return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
    }

    /**
     * Envia la solicitud a la Api para consultar los datos del convenio asociado al id
     *
     * @returns respuesta del servidor o mensaje de error
     */
    consultarCampanaId( token: any, datos: any ){
      let accion = 'campanas/consultarCampanaId/';
      let headers = new HttpHeaders({
        'content-type': 'application/json',
        'Authorization': 'Bearer '+token,
      });
      var options =  {
          headers: headers
      };
      return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
    }

  /*-------------------------------- /CAMPAÑAS ------------------------------------*/

}
