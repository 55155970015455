import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer, Meta, Title } from '@angular/platform-browser';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {map} from 'rxjs/operators';
import {OwlCarousel} from 'ngx-owl-carousel';
//providers
import { PaginaProviderService } from '../../providers/paginas/paginas/pagina-provider.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('owlElement') owlElement: OwlCarousel;

  public contenido: any;
  public vistaOwl:any = false;
  public tituloOwl: any;
  public elementosOwl: any = [];
  public vistaBanner: any = false;
  public elementosBanner: any = [];

  constructor( private PaginaService: PaginaProviderService, private router: Router, private activateRoute: ActivatedRoute, private sanitizer: DomSanitizer, private meta: Meta, private title: Title, @Inject(DOCUMENT) private document: any ){


  }

  ngOnInit() {
    this.cargarDatos( "inicio" );
  }

  /**
   * Obtiene la informacion de la pagina
   *
   * @param String   la url de la pagina 'web/Urlpagina'
   */
   cargarDatos( url: any ){
     let urlcompleta = "/"+url;
     let datos = {
      url: urlcompleta,
    }
     this.PaginaService.consultaContenidoPagina( datos ).subscribe(
       ( data ) => {
         let that = this;
         let datos = data.datos;
         if( data.datos.seo != null ){
          that.cargarSeo( data.datos.seo );
         }
         if( datos.complementos != undefined ){
           if( datos.complementos != false ){
             //OWL
             if( datos.complementos.owl != false ){
               that.vistaOwl = true;
               that.tituloOwl = datos.complementos.owl.tituloOwl;
               that.elementosOwl = datos.complementos.owl.elementosOwl;
             }else{
               that.vistaOwl = false;
               that.tituloOwl = "";
               that.elementosOwl = [];
             }
             //BANNER
             if( datos.complementos.banner != false ){
               that.vistaBanner = true;
               that.elementosBanner = datos.complementos.banner;
             }else{
               that.vistaBanner = false;
               that.elementosBanner = [];
             }
           }
         }
         that.cargarContenido( data.datos.contenido );
       },
       ( error ) => {
         if( error.status == 500 ){
           console.log( "error en el servidor");
         }
       }
     );
   }

   /**
    * Carga el contenido de la pagina que retorna el servidor
    *
    * @param String   codigo html almacenado en la base de datos
    */
   cargarContenido( contenido ){
     this.contenido = this.sanitizer.bypassSecurityTrustHtml( contenido );
   }

   /**
    * Carga el contenido del seo de la pagina
    *
    * @param Object   datos del seo
    */
   cargarSeo( seo ){
     this.title.setTitle( this.decode_utf8( seo.titulo ) );

     let link: HTMLLinkElement = this.document.createElement('link');
     link.setAttribute('rel', 'canonical');
     this.document.head.appendChild(link);
     link.setAttribute('href', seo.UrlPortal+'/inicio');

     this.meta.addTag({ name: 'robots', content: 'all' });
     this.meta.addTag({ name: 'description', content: this.decode_utf8( seo.descripcion )  });
     this.meta.addTag({ name: 'keywords', content: this.decode_utf8( seo.palabrasClaves ) });
     this.meta.addTag({ name: 'author', content: seo.autor });

     this.meta.addTag({ itemprop: 'name', content: seo.tituloPortal});
     this.meta.addTag({ itemprop: 'url', content: seo.UrlPortal });
     this.meta.addTag({ itemprop: 'thumbnailUrl', content: seo.imagen });
     let link2: HTMLLinkElement = this.document.createElement('link');
     link2.setAttribute('rel', 'image_src');
     this.document.head.appendChild(link2);
     link2.setAttribute('href', seo.imagen);
     this.meta.addTag({ itemprop: 'image', content: seo.imagen });

     this.meta.addTag({ name: 'evisit-after', content: '1 days' });
     this.meta.addTag({ name: 'distribution', content: 'global' });
     this.meta.addTag({ name: 'format-detection', content: 'telephone=no' });

     //facebook
     this.meta.addTag({ property: 'og:locale', content: 'es_LA' });
     this.meta.addTag({ property: 'og:type', content: 'Organization' });
     this.meta.addTag({ property: 'og:title', content: this.decode_utf8( seo.titulo ) });
     this.meta.addTag({ property: 'og:description', content: this.decode_utf8( seo.descripcion ) });
     this.meta.addTag({ property: 'og:url', content: window.location.href });
     this.meta.addTag({ property: 'og:site_name', content: seo.tituloPortal });
     this.meta.addTag({ property: 'og:image', content: seo.imagen });

     this.meta.addTag({ property: 'fb:app_id', content: seo.fbAppid });

     //twitter
     this.meta.addTag({ name: 'twitter:card', content: 'summary' });
     this.meta.addTag({ name: 'twitter:site', content: seo.UrlPortal });
     this.meta.addTag({ name: 'twitter:title', content: this.decode_utf8( seo.titulo ) });
     this.meta.addTag({ name: 'twitter:description', content: this.decode_utf8( seo.descripcion ) });
     this.meta.addTag({ name: 'twitter:creator', content: seo.autor });
   }
   /**
    * Decodifica el utf8 recibido
    *
    * @param String    texto para decodificar
    */
   decode_utf8(s) {
     try{
         return decodeURIComponent(escape(s));
     }catch(err){
       return s.replace("Ã‰", "É");
     }
   }

}
