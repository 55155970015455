import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ENV } from '../../../conf/ENV';
import { ResponseInformationObjectInterface } from '../../wrappers/response-information-object-interface';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NoticiasService {

    public environment: any;
    public configUrl: any;

    constructor( private http: HttpClient, private env: ENV ) {
      this.environment = env.getEnv();
      this.configUrl = this.environment.API_URL + "/noticias/";
    }

    /**
     * Envia la solicitud a la Api para consultar los datos las noticias
     *
     * @returns respuesta del servidor o mensaje de error
     */
    consultarNoticias(  ){
      let accion = 'consultarNoticias/';
      let headers = new HttpHeaders({
        'content-type': 'application/json',
      });
      var options =  {
          headers: headers
      };
      return this.http.get<ResponseInformationObjectInterface>( this.configUrl+accion, options);
    }

    /**
     * Envia la solicitud a la Api para consultar los datos de la noticia
     *
     * @returns respuesta del servidor o mensaje de error
     */
    consultarNoticiaUrl( datos ){
      let accion = 'consultarNoticiaUrl/';
      let headers = new HttpHeaders({
        'content-type': 'application/json',
      });
      var options =  {
          headers: headers
      };
      return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
    }


}
