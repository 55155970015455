import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ENV } from '../../../conf/ENV';
import { ResponseInformationObjectInterface } from '../../wrappers/response-information-object-interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SedeProviderService {

  public environment: any;
  public configUrl: any;

  constructor( private http: HttpClient, private env: ENV ) {
    this.environment = env.getEnv();
    this.configUrl = this.environment.API_URL + "/sedes/";
  }


  /**
   * Envia la solicitud a la Api para la cnsultar los datos principales de la sede
   *
   * @param object url de la sede
   * @returns respuesta del servidor o mensaje de error
   */
  consultaDatosSede( datos:any ){
    let accion = 'consultaSede/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
   * Envia la solicitud a la Api para la cnsultar los datos de promociones de la sede
   *
   * @param object url de la sede
   * @returns respuesta del servidor o mensaje de error
   */
  consultaPromocionesSede( datos:any ){
    let accion = 'consultaPromocionSede/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
   * Envia la solicitud a la Api para la cnsultar los datos de promociones de la sede
   *
   * @param object url de la sede
   * @returns respuesta del servidor o mensaje de error
   */
  estadosFinancieros( datos:any ){
    let accion = 'estadosFinancieros/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
   * Envia la solicitud a la Api para la cnsultar seo de la sede
   *
   * @param object url de la sede
   * @returns respuesta del servidor o mensaje de error
   */
  consultarSeoSede( datos: any ){
    let accion = 'datosYSeo/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
   * Envia la solicitud a la Api para la cnsultar seo de la sede
   *
   * @param object url de la sede
   * @returns respuesta del servidor o mensaje de error
   */
  consultarProfesionales( datos: any ){
    let accion = 'consultaProfesionalesSede/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
   * Envia la solicitud a la Api para consultar las demas sedes
   *
   * @returns respuesta del servidor o mensaje de error
   */
  consultaCiudadesSedes(  ){
    let accion = 'consultaCiudadesSedes/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.get<ResponseInformationObjectInterface>( this.configUrl+accion,  options );
  }

  /**
   * Envia la solicitud a la Api para consultar los datos del profesional
   *
   * @returns respuesta del servidor o mensaje de error
   */
  consultarPerfilProfesional( datos: any ){
    let accion = 'consultarPerfilProfesional/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }
}
