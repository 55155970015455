import {Component, OnInit, Inject,ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';


//providers
import { PartnersService } from '../../../providers/partners/partners.service';


@Component({
  selector: 'app-convenios',
  templateUrl: './convenios.component.html',
  styleUrls: ['./convenios.component.css']
})
export class ConveniosComponent implements OnInit {

  public token: any;
  public convenios: any = [];
  public convenio: any = {};
  public idUsuario: any;
  public vistaConvenio: any = {};
  public contenido: any;

  constructor( private partnerProvider: PartnersService , private router: Router, private activateRoute: ActivatedRoute, private sanitizer: DomSanitizer, private meta: Meta, private title: Title, @Inject(DOCUMENT) private document: any, public snackBar: MatSnackBar ){

  }

  ngOnInit() {
    this.token = this.obtenerToken();
    if( this.token == false ){
      this.mostrarAlerta('La sesión ha expirado, vuelve a iniciar sesión.', 8000);
      this.logOut();
    }
    this.cargarDatos( this.token);
  }

  /**
   * Obtiene el token almacenado en el localStorage
   *
   * @returns String con la cadena del token o Booleano si no hay sesion abierta
   */
  obtenerToken(){
    let salida: any;
    let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
    if( datos.sesion == 0 ){
      salida = false;
    }else{
      salida = datos.token;
      this.idUsuario = datos.idUsuario;
    }
    return salida;
  }

  /**
   * Carga los dato
   *
   * @param String   token almacenado en  localStorage
   *
   */
  cargarDatos( token: any ){
    let datos = {
      idSede: this.idUsuario
    }
    this.partnerProvider.consultarConvenios( token, datos ).subscribe(
      ( data ) => {
        let that = this;
        let datos = data.datos;
        that.convenios = data.datos;
        that.verConvenio(that.convenios[0].idConvenio);
      },
      ( error ) => {
        if( error.status == 401){
           this.mostrarAlerta('La sesión ha expirado, vuelve a iniciar sesión.', 8000);
           //this.logOut();
         }
         if( error.status == 500){
           this.mostrarAlerta('No se pudo actualizar la información.', 8000);
         }
      }
    );
  }

  /**
   * Carga los dato
   *
   * @param Integer   id del convenio
   *
   */
  verConvenio( idConvenio ){
    let datos = {
      idConvenio: idConvenio
    };
    this.partnerProvider.consultarConvenioId( this.token, datos ).subscribe(
      ( data ) => {
        let that = this;
        let datos = data.datos;
        that.vistaConvenio = datos;
        that.contenido = that.sanitizer.bypassSecurityTrustHtml( datos.descripcion );
      },
      ( error ) => {
        if( error.status == 401){
           this.mostrarAlerta('La sesión ha expirado, vuelve a iniciar sesión.', 8000);
           //this.logOut();
         }
         if( error.status == 500){
           this.mostrarAlerta('No se pudo actualizar la información.', 8000);
         }
      }
    )
  }

  /**
   * Decodifica el utf8 recibido
   *
   * @param String    texto para decodificar
   */
  decode_utf8(s) {
    try{
        return decodeURIComponent(escape(s));
    }catch(err){
      return s.replace("Ã‰", "É");
    }
  }

  /**
   * Realiza el cierre de sesion
   *
   * @returns hace el cierre de sesion y redirecciona al login
   */
  logOut(){
    let datos = {
      sesion: 0
    }
    localStorage.setItem( 'sesion', JSON.stringify( datos ) );
    location.href='/inicio';
  }

  /**
 * Genera una alerta para el usuario
 *
 * @param String mensaje que se va a mostrar
 * @param Integer tiempo en milisegundos
 *
 * @returns Muesta la alerta en la parte inferior de la pantalla
 */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }
}
