import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { HomeComponent } from './content/home/home.component';


//provider
import { MenuPrincipalProviderService } from './providers/menus/menuPrincipal/menu-principal-provider.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  events: string[] = [];
  opened: boolean;
  active: any = false;
  public isCollapsed = true;
  public token: any;
  public vistaBtnPartner: any = false;
  public idUsuario: any;
  public busqueda: any = "";
  public tipoMenu: any = 0;

  constructor( private router: Router, private activateRoute: ActivatedRoute, public snackBar: MatSnackBar,  private menuProvider: MenuPrincipalProviderService ){
  }

  ngOnInit() {
     this.token = this.obtenerToken();
     this.cargarConfiguracionMenuPrincipal();
     if( this.token != false ){
       this.vistaBtnPartner = true;
     }
     window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  /**
  * Carga la configuracion del tipo de menu
  *
  */
  cargarConfiguracionMenuPrincipal(){
    this.menuProvider.consultarConfiguracionMenuPrincipal().subscribe(
      ( data ) => {
        let datos = data.datos;
        let that = this;
        that.tipoMenu = datos.tipoMenu;

      },
      ( error ) => {

      }
    );
  }

  /**
  * lee el scroll de la pagina para hacer cambio de clase del menu
  *
  */
  scroll(){
    let elemento = document.getElementById('contenido');
    let posicion = elemento.getBoundingClientRect();
    if( posicion.top < -200 ){
      document.getElementById("toolbar").className = document.getElementById("toolbar").className.replace( /(?:^|\s)sticky(?!\S)/g , ' fixed' );
    }else{
      document.getElementById("toolbar").className = document.getElementById("toolbar").className.replace( /(?:^|\s)fixed(?!\S)/g , ' sticky' );
    }

  }
  /**
  * realiza la validacion de una busqueda y redireccina para ver los resultados
  *
  */
  buscar(){
   let validacion = this.busqueda.replace(/ /g, "");
   if( validacion != "" ){
    let palabraBuscar = this.busqueda.replace(/ /g, "_");
    this.router.navigate(['/buscar', palabraBuscar ]);
   }
  }

  /**
  * Obtiene el token almacenado en el localStorage
  *
  * @returns String con la cadena del token o Booleano si no hay sesion abierta
  */
  obtenerToken(){
   let salida: any;
   let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
   if( datos != null ){
     if( datos.sesion == 0 ){
       salida = false;
     }else{
       this.idUsuario = datos.idUsuario;
       salida = datos.token;
     }
   }else{
     salida = false;
     let datos = {
       sesion: 0
     }
     localStorage.setItem( 'sesion', JSON.stringify( datos ) );
   }


   return salida;
  }

  activarMenu(){
   this.active = !this.active;
  }

  /**
  * Realiza el cierre de sesion
  *
  * @returns hace el cierre de sesion y redirecciona al login
  */
  logOut(){
   let datos = {
     sesion: 0
   }
   localStorage.setItem( 'sesion', JSON.stringify( datos ) );
   this.vistaBtnPartner = false;
   location.href='/inicio';
  }

}
