import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { SidebarModule } from 'ng-sidebar';

import {
    MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonModule, MatButtonToggleModule,
    MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatDividerModule,
    MatExpansionModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule,
    MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule,   MatRippleModule, MatSelectModule,
    MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule,
    MatTabsModule, MatToolbarModule, MatTooltipModule,   MatTreeModule,
} from '@angular/material';
import { AgmCoreModule } from '@agm/core';
import { OwlModule } from 'ngx-owl-carousel';

//Providers
import { MenuPrincipalProviderService } from './providers/menus/menuPrincipal/menu-principal-provider.service';
import { MenuFootProviderService } from './providers/menus/menuFoot/menu-foot-provider.service';
import { PaginaProviderService } from './providers/paginas/paginas/pagina-provider.service';
import { CorreoProviderService } from './providers/correos/correo-provider.service';
import { PartnersService } from './providers/partners/partners.service';
import { BusquedaProviderService } from './providers/busqueda/busqueda-provider.service';

// Environment configuration
import { ENV } from '../conf/ENV';

//Paginas
import { AppComponent } from './app.component';
import { NavbarComponent } from './content/navbar/navbar.component';
import { HomeComponent } from './content/home/home.component';
import { SectionComponent } from './content/section/section.component';
import { SedeComponent } from './content/sede/sede.component';
import { FootComponent } from './content/foot/foot.component';
import { NuestraSedeComponent } from './content/sede/nuestra-sede/nuestra-sede.component';
import { StaffComponent } from './content/sede/staff/staff.component';
import { PromocionesComponent } from './content/sede/promociones/promociones.component';
import { EstadosFinancierosComponent } from './content/sede/estados-financieros/estados-financieros.component';
import { ContactenosComponent } from './content/sede/contactenos/contactenos.component';
import { PerfilProfesionalComponent } from './content/perfil-profesional/perfil-profesional.component';
import { PartnerComponent } from './content/partner/partner.component';
import { NoticiasComponent } from './content/partner/noticias/noticias.component';
import { ConveniosComponent } from './content/partner/convenios/convenios.component';
import { CampanasComponent } from './content/partner/campanas/campanas.component';
import { ConvenioComponent } from './content/convenio/convenio.component';
import { SugerenciasComponent } from './content/sugerencias/sugerencias.component';
import { NoticiasPortalComponent } from './content/noticias-portal/noticias-portal.component';
import { ContactoComponent } from './content/contacto/contacto.component';
import { SemarcaComponent } from './content/contacto/semarca/semarca.component';
import { ListaNoticiasComponent } from './content/noticias-portal/lista-noticias/lista-noticias.component';
import { BusquedaComponent } from './content/busqueda/busqueda.component';
import { MenuSuperiorComponent } from './content/navbar/menu-superior/menu-superior.component';


//Routing
const appRoutes: Routes = [
    {   path: '', component: HomeComponent },
    {   path: 'inicio', component: HomeComponent },
    {   path: 'web/:url', component: SectionComponent },
    {   path: 'buscar/:busqueda', component: BusquedaComponent },
    {   path: 'sede/:url', component: SedeComponent },
    {   path: 'profesional/:idSede/:idProfesional', component: PerfilProfesionalComponent },
    {   path: 'partners', component: PartnerComponent },
    {   path: 'convenio', component: ConvenioComponent },
    {   path: 'contacto', component: ContactoComponent },
    {   path: 'se-parte-de-nuestra-marca', component: SemarcaComponent },
    {   path: 'sugerencias', component: SugerenciasComponent },
    {   path: 'blog', component: ListaNoticiasComponent },
    {   path: 'blog/:url', component: NoticiasPortalComponent },


];
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    SectionComponent,
    SedeComponent,
    FootComponent,
    NuestraSedeComponent,
    StaffComponent,
    PromocionesComponent,
    EstadosFinancierosComponent,
    ContactenosComponent,
    PerfilProfesionalComponent,
    PartnerComponent,
    NoticiasComponent,
    ConveniosComponent,
    CampanasComponent,
    ConvenioComponent,
    SugerenciasComponent,
    NoticiasPortalComponent,
    ContactoComponent,
    SemarcaComponent,
    ListaNoticiasComponent,
    BusquedaComponent,
    MenuSuperiorComponent
  ],
  imports: [
    BrowserModule,
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCWsYWaWBpm2OuvNq3CnKVMC72tATLUbkg'
    }),
    OwlModule,
    FormsModule,
    SidebarModule.forRoot()
  ],
  providers: [
      ENV,
      MenuPrincipalProviderService,
      PaginaProviderService,
      MenuFootProviderService,
      CorreoProviderService,
      PartnersService,
      BusquedaProviderService
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
