import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { OwlCarousel } from 'ngx-owl-carousel';

//Providers
import { SedeProviderService } from '../../providers/sedes/sede-provider.service';

@Component({
  selector: 'app-perfil-profesional',
  templateUrl: './perfil-profesional.component.html',
  styleUrls: ['./perfil-profesional.component.css']
})
export class PerfilProfesionalComponent implements OnInit {

  public idProfesional: any;
  public idSede: any;
  public profesional: any = {};
  public sede: any = {};


  constructor(private sedeProvider: SedeProviderService, private router: Router, private activateRoute: ActivatedRoute ) {
  }

  ngOnInit() {
    this.activateRoute.paramMap.subscribe(
      ( map ) => {
        let that = this;
        that.idProfesional = map.get('idProfesional');
        that.idSede = map.get('idSede');
        that.cargarDatos( that.idProfesional, that.idSede );
      }
    );
  }

  /**
  * Obtiene la informacion del profesional
  *
  * @param Integer   el id del profesional
  */
  cargarDatos( idProfesional: any, idSede: any ){
    let datos = {
      idProfesional: idProfesional,
      idSede: idSede
    };
    this.sedeProvider.consultarPerfilProfesional( datos ).subscribe(
      ( data ) => {
        let that = this;
        let datos = data.datos;
        that.profesional = datos;
        that.sede = datos.sede;
      },
      ( error ) => {
        console.log( error );
      }
    );
  }

  /**
   * Decodifica el utf8 recibido
   *
   * @param String    texto para decodificar
   */
  decode_utf8(s) {
    try{
        return decodeURIComponent(escape(s));
    }catch(err){
      return s.replace("Ã‰", "É");
    }
  }

}
