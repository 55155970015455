import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ENV } from '../../../conf/ENV';
import { ResponseInformationObjectInterface } from '../../wrappers/response-information-object-interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusquedaProviderService {

  public environment: any;
  public configUrl: any;

  constructor( private http: HttpClient, private env: ENV ) {
    this.environment = env.getEnv();
    this.configUrl = this.environment.API_URL + "/busqueda/";
  }

  /**
   * Envia la solicitud a la Api para realizar la busqueda dentro del contenido de las paginas
   *
   * @param object los datos de la busqueda
   * @returns respuesta del servidor o mensaje de error
   */
  buscarContenido( datos:any ){
    let accion = 'buscarContenido/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

}
