import { Component, OnInit,  ViewChild, ElementRef  } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Md5 } from "md5-typescript";
import { MatSnackBar } from '@angular/material';

//provider
import { MenuFootProviderService } from '../../providers/menus/menuFoot/menu-foot-provider.service';
import { SedeProviderService } from '../../providers/sedes/sede-provider.service';
import { PaginaProviderService } from '../../providers/paginas/paginas/pagina-provider.service';
import { CorreoProviderService } from '../../providers/correos/correo-provider.service';
import { PartnersService } from '../../providers/partners/partners.service';

@Component({
  selector: 'app-foot',
  templateUrl: './foot.component.html',
  styleUrls: ['./foot.component.css']
})
export class FootComponent implements OnInit {

  public elementos: any =[];
  public correoEnviar: any = "";
  public tipoCorreo: any = "";
  public correo: any = {};
  public vistaSedesSelect: any = true;
  public ciudades: any = [];
  public sedes: any = [];
  public usuario: any = {};
  public mensajeSesion: any = "";
  public alertaSesion: any = false;
  public token: any;
  public vistaBtnPartner: any = false;
  public error: any = false;
  public mensaje: any = "";
  public newsletter: any = "";
  public errorNewsLetter: any = false;
  public mensajeNewsLetter: any = "";
  public contNewsLetter: any = true;
  public enviandoCorreo: any = false;

  @ViewChild('btnCloseModalLogin')  btnCloseModalLogin: ElementRef ;
  constructor( private MenuFootProvider: MenuFootProviderService, private sedeProvider: SedeProviderService, private paginaProvider: PaginaProviderService, private router: Router, private correoProvider: CorreoProviderService, private partnersProvider: PartnersService, public snackBar: MatSnackBar ){

  }

  ngOnInit() {
    this.cargarDatos();
    this.token = this.obtenerToken();
    if( this.token == false ){
      this.vistaBtnPartner = false;
    }else{
      this.vistaBtnPartner = true;
    }
  }

  /**
   * Carga los del menu principal
   *
   */
  cargarDatos(){
    this.MenuFootProvider.consultaMenuFoot().subscribe(
      ( data ) => {
        let that = this;
        that.cargarElementosMenuFoot( data.datos );
      },
      ( error ) =>{
        console.log( error );
      }
    );

  }

  /**
   * Obtiene el token almacenado en el localStorage
   *
   * @returns String con la cadena del token o Booleano si no hay sesion abierta
   */
  obtenerToken(){
    let salida: any;
    let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
    if( datos != null ){
      if( datos.sesion == 0 ){
        salida = false;
      }else{
        salida = datos.token;
      }

    }else{
      salida = false;
      let datos = {
        sesion: 0
      }
      localStorage.setItem( 'sesion', JSON.stringify( datos ) );
    }
    return salida;
  }

  /**
   * Carga los datos menu principal
   *
   * @param Array   elementos del menu principal
   */
  cargarElementosMenuFoot( elementos: any ){
    this.elementos = elementos;
  }

  suscribirNewsletter(){
    if ( ! /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test( this.newsletter ) ){
      this.mostrarAlerta( "Direccion de correo no valido", 8000);
    }else{
      let datos = {
        'correo': this.newsletter
      };
      this.correoProvider.enviarNewsLetter( datos ).subscribe(
        ( data ) => {
            this.mensajeNewsLetter = "El registro se realizo correctamente.";
            this.errorNewsLetter = true;
            this.contNewsLetter = false;
        },
        ( error ) =>{
          this.mensajeNewsLetter = "Ocurrio un error, intentelo mas tarde.";
          this.errorNewsLetter = true;
          this.contNewsLetter = true;
        }
      );
    }
  }

  /**
   * Genera una alerta para el usuario
   *
   * @param String mensaje que se va a mostrar
   * @param Integer tiempo en milisegundos
   *
   * @returns Muesta la alerta en la parte inferior de la pantalla
   */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }



  /**
  * Hace el envio del del correo
  *
  * @param Array   elementos del menu principal
  */
  enviarCorreo(){
    this.error = false;
    this.mensaje = "";
    let datos = {
      'correo': this.correo
    };
    let validacion = this.validarInformacion();
    this.error = validacion;
    if( validacion[0] == false ){
      this.mensaje = "Enviando";
      this.enviandoCorreo = true;
      this.correoProvider.enviarCorreoContacto( datos ).subscribe(
        ( data ) => {
          let  that = this;
          that.error = true;
          that.mensaje = "El mensaje se envio correctamente";
          that.correo = {};
          this.enviandoCorreo = false;
        },
        ( error ) => {
          let  that = this;
          that.error = true;
          that.mensaje = "Error al enviar el mensaje, vuelve a intentar mas tarde.";
          this.enviandoCorreo = false;
        }
      );
    }else{
      this.mensaje = validacion[1];
    }
  }

  /**
   * valida la informacion para el envio del formulario
   *
   * @returns Array [0] booleano con respuesta de validacion y [1]mensaje de error
   */
  validarInformacion(){
    let mensaje = "";
    let error = false;
    //validaciones
    if (  this.correo.nombre == undefined && this.correo.correo == undefined  && this.correo.mensaje == undefined ){
      mensaje = "Los campos estan vacios";
      error = true;
    }else if (  this.correo.nombre == "" && this.correo.correo == ""  && this.correo.mensaje == "" ){
      mensaje = "Los campos estan vacios";
      error = true;
    }else if( this.correo.nombre == undefined || this.correo.correo == undefined  || this.correo.mensaje == undefined ){
      mensaje = "los datos estan incompletos";
      error = true;
    }else if ( ! /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.correo.correo)){
      mensaje = "Direccion de correo no valido";
      error = true;
    }
    return [ error, mensaje ];
  }

  /**
   * Decodifica el utf8 recibido
   *
   * @param String    texto para decodificar
   */
  decode_utf8(s) {
    try{
        return decodeURIComponent(escape(s));
    }catch(err){
      return s.replace("Ã‰", "É");
    }
  }
}
